@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Outfit", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.6rem;
}

h1 {
  font-size: 6rem;
}

.app {
  width: 100%;
  min-height: 100vh; /* Change height to min-height */
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  overflow: hidden; /* Ajoutez overflow: hidden pour masquer le contenu qui dépasse de la fenêtre. */
}

.app:before {
  content: "";
  background: url("./assets/landscape-nature-forest-wilderness-mountain-cloud-910559-pxhere.com.jpg")
    no-repeat center center/cover;
  position: fixed; /* Utilisation de "fixed" pour que l'image de fond reste fixe lorsque vous faites défiler la page */
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.app .search {
  text-align: center;
  padding: 1rem;
}

.app input {
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
}

::placeholder {
  color: #f8f8f8;
}

.container {
  max-width: 700px;
  height: 700px;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app .top {
  width: 100%;
  margin: 1rem auto;
}

.app .img {
  display: flex;
  justify-content: center;
}

.app .description {
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(269deg);
}

.app .bottom {
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
}

.bold {
  font-weight: 700;
}
